<template>
    <div class="max-w-md">
        <h1 class="font-bold text-primary">
            Thank you for participating in the first ever EAIE Community
            Exchange.
        </h1>
        <!-- wp:paragraph -->
        <p>
            This platform will remain open until 01 November with the following
            features:
        </p>
        <!-- /wp:paragraph -->

        <!-- wp:list -->
        <ul>
            <li>
                On-demand sessions including all plenaries and previously live
                discussions
            </li>
            <li>
                Downloadable PowerPoint presentations of sessions (coming soon)
            </li>
            <li>Participant search and messaging options</li>
            <li>Exhibition and messaging to contact the exhibitors</li>
            <li>
                Poster gallery and messaging options to contact the poster
                presenters
            </li>
        </ul>
        <!-- /wp:list -->

        <!-- wp:paragraph -->
        <p>
            After 18 October, the platform will no longer allow scheduling of
            video meetings.
        </p>
        <!-- /wp:paragraph -->

        <!-- wp:paragraph -->
        <p class="notification">
            <strong
                >Support is no longer provided for this platform. Please contact </strong
            ><a href="mailto:info@eaie.org"><strong>info@eaie.org</strong></a
            ><strong> with any questions you might have.</strong>
        </p>
        <!-- /wp:paragraph -->

        <!-- wp:heading -->
        <h2>
            <strong class="text-primary">FREQUENTLY ASKED QUESTIONS</strong>
        </h2>
        <!-- /wp:heading -->

        <!-- wp:heading {"level":4} -->
        <h4>
            Can I download sessions, or will they be made available elsewhere?
        </h4>
        <!-- /wp:heading -->

        <!-- wp:paragraph -->
        <p>
            All session recordings will be available for you to watch and
            revisit until 01 November. The recordings can only be viewed within
            the platform and will not be accessible elsewhere. Some content from
            the Community Exchange may be made available for EAIE members in
            2021.
        </p>
        <!-- /wp:paragraph -->

        <!-- wp:heading {"level":4} -->
        <h4>Will the PowerPoints of the presentations be made available?</h4>
        <!-- /wp:heading -->

        <!-- wp:paragraph -->
        <p>
            Yes, all PowerPoints presentations that we receive from the speakers
            will be uploaded to the platform as soon as they are available.
        </p>
        <!-- /wp:paragraph -->

        <!-- wp:heading {"level":3} -->
        <h3>LOGGING IN</h3>
        <!-- /wp:heading -->

        <!-- wp:paragraph -->
        <p>
            Since you are accessing these FAQs from within the virtual meeting,
            we assume you were able to log in successfully.
        </p>
        <!-- /wp:paragraph -->

        <!-- wp:heading {"level":4} -->
        <h4><strong>Can someone else log in for me?</strong></h4>
        <!-- /wp:heading -->

        <!-- wp:paragraph -->
        <p>
            No, your login information is specific to you and should be kept
            private. For security purposes, you can only be logged in from one
            location at a time.
        </p>
        <!-- /wp:paragraph -->

        <!-- wp:heading {"level":4} -->
        <h4>
            <strong
                >What equipment and technical specifications are needed to
                access and participate in the event?</strong
            >
        </h4>
        <!-- /wp:heading -->

        <!-- wp:paragraph -->
        <p>
            If you can access the web, you can participate! However, for the
            best online and interactive experience, we strongly recommend using
            a PC or Mac with a keyboard, camera, microphone, and strong internet
            connection.
        </p>
        <!-- /wp:paragraph -->

        <!-- wp:paragraph -->
        <p>In general, you will need the following:</p>
        <!-- /wp:paragraph -->

        <!-- wp:list -->
        <ul>
            <li>
                PC, Mac, tablet, or smartphone (PC or Mac is highly recommended)
            </li>
            <li>Internet connection (wired is more reliable than Wi-Fi)</li>
            <li>
                Modern web browser. We recommend the latest versions of Google
                Chrome, Firefox or Microsoft Edge. Internet Explorer 11 is not
                supported.
            </li>
            <li>
                <strong>The latest version of your chosen web browser. </strong
                >The event platform will function in several versions of Chrome,
                however it will not function in older versions of Microsoft
                Edge. If you are using Microsoft Edge, please make sure you have
                the latest version. You can download it<a
                    href="https://www.microsoft.com/en-us/edge?form=MY01BL&amp;OCID=MY01BL"
                    ><strong> here.</strong></a
                >
            </li>
            <li>
                If you plan to participate from a tablet or smartphone, there
                are certain features that will not be enabled for you, including
                screen sharing and chat in the various break-out rooms or
                private meetings.
            </li>
        </ul>
        <!-- /wp:list -->

        <!-- wp:heading {"level":3} -->
        <h3><strong>Exhibition</strong></h3>
        <!-- /wp:heading -->

        <!-- wp:heading {"level":4} -->
        <h4>Where can I find exhibitor information?</h4>
        <!-- /wp:heading -->

        <!-- wp:paragraph -->
        <p>
            Click on the ‘EXHIBITION’ tab in the top menu to view additional
            information and connect with the exhibitors.
        </p>
        <!-- /wp:paragraph -->

        <!-- wp:heading {"level":3} -->
        <h3><strong>Communicating with other participants</strong></h3>
        <!-- /wp:heading -->

        <!-- wp:heading {"level":4} -->
        <h4>Where can I see who is attending?</h4>
        <!-- /wp:heading -->

        <!-- wp:paragraph -->
        <p>
            Click on the ‘PARTICIPANTS’ tab on the top menu bar. You can search
            for participants by first name, last name or organisation, and you
            can filter by country.
        </p>
        <!-- /wp:paragraph -->

        <!-- wp:heading {"level":4} -->
        <h4>Can I contact or message other participants?</h4>
        <!-- /wp:heading -->

        <!-- wp:paragraph -->
        <p>
            Yes, you can search for a participant by first name, last name, or
            institution under the ‘PARTICIPANTS’ tab on the top menu bar. Then
            click on the ‘Send a Message’ icon.
        </p>
        <!-- /wp:paragraph -->

        <!-- wp:heading {"level":4} -->
        <h4>I cannot find someone in the participant directory?</h4>
        <!-- /wp:heading -->

        <!-- wp:paragraph -->
        <p>
            Are you certain they are attending? If so they may have set their
            profile preference to not show in the participant directory.
        </p>
        <!-- /wp:paragraph -->
    </div>
</template>

<script>
export default {
    name: "helpEaie"
};
</script>

<style lang="scss" scoped>
@import "../../styles/setup/colors";
@import "../../styles/views/help";
</style>
